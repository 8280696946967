<template>
  <div>
    <component :is="FastLoading" />

    <!-- Início do modal -->
    <div
      id="modal-finalizar-live"
      class="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Finalizar live</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <h4 class="text-danger">
              Tem certeza que deseja finalizar a live?
            </h4>
            <button
              id="btnFinalizaLiveConfirma"
              class="btn btn-danger btn-lg mt-4"
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fim do modal -->
    <!-- fd-wrap -->
    <div v-if="!$store.state.fastCarregando && fastLive.permissao">
      <div
        v-if="fastLive.tipo == 'A' && !fastLive.finalizou && fastLive.iniciou"
        id="body_conteudo_live"
        class="container-fluid"
      >
        <div id="divCabLive" class="row text-center pt-3">
          <div class="col-md-2">
            <img
              style="max-height: 70px"
              :src="imageSiteLogoHorizontal"
              class="img-fluid"
            />
          </div>
          <div class="col-md-2">
            <div id="divInfoDuracao">
              <strong>Duração</strong>
              <p>{{ fastLive.duracao_live }}</p>
            </div>
          </div>
          <div class="col-md-2">
            <div id="divInfoTitulo">
              <strong>Título</strong>
              <p>{{ fastLive.titulo_live }}</p>
            </div>
          </div>
          <div class="col-md-2">
            <div id="divInfoDetalhes">
              <strong>Detalhes</strong>
              <p>{{ fastLive.detalhes }}</p>
            </div>
          </div>
          <div class="col-md-2">
            <div id="divInfoPlataforma">
              <strong>Plataforma</strong>
              <p>{{ fastPlataforma.nome_plataforma }}</p>
            </div>
          </div>
          <div class="col-md-2">
            <h4>Layouts</h4>

            <div v-if="isMobile()" class="fast-live-mobile">
              <span
                id="btnLayoutOriginalMob"
                class="btn btn-danger"
                title="Layout original"
                @click="fastLayoutOriginalMob()"
                >A</span
              >
              <span
                id="btnLayoutTutorMob"
                class="btn btn-outline-danger"
                title="Apenas tutor"
                @click="fastLayoutTutorMob()"
                >B</span
              >
              <span
                id="btnLayoutShareMob"
                class="btn btn-outline-danger"
                title="Apenas compartilhamento"
                @click="fastLayoutShareMob()"
                >C</span
              >
              <span
                id="btnLayoutChatMob"
                class="btn btn-outline-danger"
                title="Apenas chat"
                @click="fastLayoutChatMob()"
                >D</span
              >
              <span
                id="btnLayoutParticipantesMob"
                class="btn btn-outline-danger"
                title="Apenas participantes"
                @click="fastLayoutParticipantesMob()"
                >E</span
              >
            </div>
            <div v-else class="fast-live-desktop">
              <span
                id="btnLayoutOriginal"
                class="btn btn-outline-danger"
                data-trigger="hover"
                data-toggle="popover"
                title="Layout original"
                data-html="true"
                data-content="<div style='width: 20px; height: 50px; background-color: #f00; float: left; margin-left: 5px; margin-right: 5px; margin-bottom: 5px;'></div><div style='width: 80px; height: 50px; background-color: #f00; float: left; margin-right: 5px; margin-bottom: 5px;'></div><div style='width: 20px; height: 50px; background-color: #f00; float: left; margin-right: 5px; margin-bottom: 5px;'></div>"
                @click="fastLayoutOriginal()"
                >A</span
              >
              <span
                id="btnLayoutTutor"
                class="btn btn-danger"
                data-trigger="hover"
                data-toggle="popover"
                title="Mais foco no tutor"
                data-html="true"
                data-content="<div style='width: 30px; height: 50px; background-color: #f00; float: left; margin-left: 5px; margin-right: 5px; margin-bottom: 5px;'></div><div style='width: 70px; height: 50px; background-color: #f00; float: left; margin-right: 5px; margin-bottom: 5px;'></div><div style='width: 20px; height: 50px; background-color: #f00; float: left; margin-right: 5px; margin-bottom: 5px;'></div>"
                @click="fastLayoutTutor()"
                >B</span
              >
              <span
                id="btnLayoutShare"
                class="btn btn-outline-danger"
                data-trigger="hover"
                data-toggle="popover"
                title="Foco no compartilhamento"
                data-html="true"
                data-content="<div style='width: 120px; height: 40px; background-color: #f00; margin: auto; margin-bottom: 5px;'></div><div style='width: 120px; height: 30px; background-color: #f00; margin: auto; margin-bottom: 5px;'></div>"
                @click="fastLayoutShare()"
                >C</span
              >
              <span
                id="btnLayoutChat"
                class="btn btn-outline-danger"
                data-trigger="hover"
                data-toggle="popover"
                title="Foco no chat"
                data-html="true"
                data-content="<div style='width: 20px; height: 50px; background-color: #f00; float: left; margin-left: 5px; margin-right: 5px; margin-bottom: 5px;'></div><div style='width: 50px; height: 30px; background-color: #f00; float: left; margin-right: 5px; margin-bottom: 5px;'></div><div style='width: 20px; height: 50px; background-color: #f00; float: left; margin-right: 5px; margin-bottom: 5px;'></div>"
                @click="fastLayoutChat()"
                >D</span
              >
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div
            id="divVideoInstrutorLive"
            class="col-sm-12 col-md-3 col-lg-3"
            style="height: auto; overflow: auto"
          >
            <div class="text-center mt-2">
              <h4>Instrutor</h4>
            </div>
            <div id="publisher" style="height: 300px"></div>
            <div id="divDetalhesLive" class="text-center">
              <div id="divTempoLive" class="row p-2">
                <fast-timer 
                    :endtime="dataTermino" 
                    trans='{  
                    "day":"Dias",
                    "hours":"Horas",
                    "minutes":"Minutos",
                    "seconds":"Segundos",
                    "running":"",
                    "upcoming":"",
                    "status": {
                        "expired":"",
                        "running":"",
                        "upcoming":""
                      }}'
                    ></fast-timer>
              </div>
              <button
                class="btn btn-primary btn-block mt-2 d-none"
                @click="start(true)"
              >
                <i class="fas fa-video" /> Iniciar chamada
              </button>
              <button
                class="btn btn-primary btn-block mt-2 d-none"
                @click="start(false)"
              >
                <i class="fas fa-video" /> Entrar
              </button>
              <button
                class="btn btn-primary btn-block mt-2 d-none"
                @click="startShareScreen()"
              >
                <i class="fas fa-video" /> Compartilhar tela
              </button>
              <button
                v-if="fastLive.tutor && !statusShareScreen"
                class="btn btn-primary btn-sm mt-2 d-block mx-auto"
                @click="compartilhaTela()"
                id="btnCompartilharTela"
              >
                <i class="fas fa-video" /> Compartilhar tela
              </button>
              <button
                v-if="fastLive.tutor"
                class="btn btn-primary btn-sm mt-2 d-block mx-auto"
                id="btnMutarAlunos"
              >
                Mutar alunos
              </button>
              <button
                v-if="fastLive.tutor"
                class="btn mt-2 btn-danger btn-sm d-block mx-auto"
                @click="showModal('modalFinalizarLive')"
              >
                Finalizar live
              </button>
              <button
                class="btn btn-primary btn-block mt-2 d-none"
                @click="stopShareScreen()"
              >
                <i class="fas fa-video" /> Interromper compartilhamento
              </button>

              <ul class="d-none">
                <li v-for="p in participantes" :key="p.uuid" :value="p.uuid">
                  {{ p.uuid }}
                </li>
              </ul>
              <div class="row p-2 mt-2" id="participantes"></div>
            </div>
          </div>
          <div
            id="divTelaCompartilhadaLive"
            class="col-sm-12 col-md-6 col-lg-7"
          >
            <div id="screen-preview" style="height: 500px"></div>
            <div id="divChat">
              <div id="textchat" class="mt-4">
                <p id="history" />
              </div>
              <div>
                <form id="formChat">
                  <input
                    id="msgTxt"
                    class="form-control"
                    type="text"
                    placeholder="Digite aqui"
                    autocomplete="off"
                  />
                </form>
              </div>
            </div>
          </div>
          <div
            id="divParticipantesLive"
            class="col-sm-12 col-md-3 col-lg-2"
            style="height: auto; overflow: auto"
          >
            <div class="text-center mt-2">
              <h4 id="h4NumeroParticipantes">Participantes</h4>
            </div>
            <div id="subscriber"></div>
          </div>
        </div>
      </div>
      <div
        v-else-if="
          (fastLive.tipo == 'J' && !fastLive.finalizou && fastLive.iniciou) ||
          fastLiveJitsiPublica
        "
        class="row"
      >
        <div id="meet" style="width: 100%; height: 100vh"></div>
      </div>
      <div
        v-else-if="
          (fastLive.tipo == 'Z' ||
            fastLive.tipo == 'A' ||
            fastLive.tipo == 'J' ||
            fastLive.tipo == 'M') &&
          !fastLive.finalizou
        "
        style="height: 100vh"
        class="d-flex align-items-center justify-content-center"
      >
        <div class="row text-center">
          <div class="col-12">
            <div>
              <img
                style="max-height: 70px"
                :src="imageSiteLogoHorizontal"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="col-12 mt-4">
            <h3 v-if="!fastLive.iniciou">Aguarde o início da live</h3>
            <h3 v-else>A live iniciou!</h3>
          </div>
          <div v-if="!fastLive.iniciou" class="col-12">
            <fast-timer 
                    :endtime="fastLive.data_live.split('T')[0] + ' ' + fastLive.hora_live" 
                    trans='{  
                    "day":"Dias",
                    "hours":"Horas",
                    "minutes":"Minutos",
                    "seconds":"Segundos",
                    "running":"",
                    "upcoming":"",
                    "status": {
                        "expired":"",
                        "running":"",
                        "upcoming":""
                      }}'
                    ></fast-timer>
            <div v-if="fastLiveAnexos.length" class="col-12 mt-4 text-center">
              <h6>Anexos da live</h6>
              <ul class="list-group" style="max-width: 400px; margin: auto">
                <li
                  v-for="arquivo in fastLiveAnexos"
                  :key="arquivo.id_live_arquivo"
                  class="list-group-item"
                >
                  <a :href="arquivo.path" target="_blank">{{
                    arquivo.descricao ? arquivo.descricao : "Sem descrição"
                  }}</a>
                </li>
              </ul>
            </div>
            <div class="col-12 mt-4 text-center">
              <a
                v-if="fastLive.id_plataforma"
                :href="'/plataforma/' + fastLive.id_plataforma"
                class="btn btn-primary mt-2"
                >Voltar para o FastEAD</a
              >
              <a
                v-else
                :href="'/minhas-plataformas'"
                class="btn btn-primary mt-2"
                >Voltar para o FastEAD</a
              >
            </div>
          </div>
          <div class="text-center m-auto" v-else>
            <a
              :href="fastLive.API_TOKEN"
              class="btn btn-primary mt-2 blink_me"
              >Acessar Live</a
            >
            <button
              v-if="fastLive.tutor"
              class="ml-2 btn mt-2 btn-danger btn-sm"
              @click="showModal('modalFinalizarLive')"
            >
              Finalizar live
            </button>
          </div>
        </div>
      </div>
      <div
        v-else-if="fastLive.finalizou"
        style="height: 100vh"
        class="d-flex align-items-center justify-content-center"
      >
        <div class="row text-center">
          <div class="col-12">
            <div>
              <img
                style="max-width: 300px"
                v-lazy="require('@/assets/images/logo_horizontal.png')"
              />
            </div>
          </div>
          <div v-if="fastLive.cancelada" class="col-12 mt-4">
            <h3>Esta live foi cancelada pelo administrador</h3>
          </div>
          <div v-else class="col-12 mt-4">
            <h3>Esta live já foi finalizada</h3>

            <div v-if="fastLiveAnexos.length" class="col-12 mt-4 text-center">
              <h6>Anexos da live</h6>
              <ul class="list-group" style="max-width: 400px; margin: auto">
                <li
                  v-for="arquivo in fastLiveAnexos"
                  :key="arquivo.id_live_arquivo"
                  class="list-group-item"
                >
                  <a :href="arquivo.path" target="_blank">{{
                    arquivo.descricao ? arquivo.descricao : "Sem descrição"
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="text-center m-auto">
            <a
              v-if="fastLive.id_plataforma"
              :href="'/plataforma/' + fastLive.id_plataforma"
              class="btn btn-primary mt-2"
              >Voltar para o FastEAD</a
            >
            <a v-else :href="'/minhas-plataformas'" class="btn btn-primary mt-2"
              >Voltar para o FastEAD</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="!$store.state.fastCarregando && !fastLive.permissao"
      style="height: 100vh"
      class="d-flex align-items-center justify-content-center"
    >
      <div class="row text-center">
        <div class="col-12">
          <div>
            <img
              style="max-height: 70px"
              :src="imageSiteLogoHorizontal"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="col-12 mt-4">
          <h3>Você não possui permissão para acessar essa live</h3>
        </div>
        <div class="text-center m-auto">
          <a :href="'/minhas-plataformas'" class="btn btn-primary mt-2"
            >Voltar para o FastEAD</a
          >
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modal -->
    <modal
      name="modalFinalizarLive"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Tem certeza que deseja finalizar a live?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalFinalizarLive')"
          >
            <i class="fa fa-times" aria-hidden="true" />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="finalizarLive()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- Notificações -->
    <notifications group="foo" position="bottom right" />
  </div>
  <!-- scripts -->
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import FastTimer from "../templates/fastead/components/FastTimer";

export default {
  name: "Live",
  mixins: [methods],
  components: {
  FastTimer,
  },
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      imageSiteHeader: settings.fastLogoPrincipal,
      fastPlataforma: "",
      //webSocketUrl: "wss://191.252.223.238:8443",
      webSocketUrl: "wss://gfbalestrin.senactech.com.br:8443",
      img: null,
      localVideo: "",
      localStream: "",
      remoteVideo: "",
      peerConnection: "",
      uuid: "",
      serverConnection: "",
      peerConnectionConfig: {
        iceServers: [
          { urls: "stun:stun.stunprotocol.org:3478" },
          { urls: "stun:stun.l.google.com:19302" },
        ],
      },
      statusMicrofone: null,
      statusCamera: null,
      statusConnection: null,
      statusShareScreen: false,
      participantes: [],

      // Compartilhamento de tela
      stream: null,
      mediaRecorder: null,
      videoShareScreen: "",

      // Informações úteis
      userAgent: "",
      language: "",
      platform: "",
      fastLive: {
        API_TOKEN: "",
        acesso: "",
        data_live: "",
        detalhes: "",
        duracao_live: "",
        nr_maximo_sala: "",
        status: "",
        tipo: "",
        titulo_live: "",
        video_registro: "",
        iniciou: false,
        finalizou: false,
        cancelada: false,
        tutor: false,
        permissao: false,
      },
      countDownSettings: {
        days: "Dias",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
      },
      // Opentok
      openTokSession: "",
      dataTermino: "",
      // Live Jitsi
      fastLiveJitsiPublica: false,
      // Anexos
      fastLiveAnexos: [],
    };
  },
  computed: {
    FastLoading() {
      return () =>
        import(`@/templates/${this.fastTemplate}/components/Loading.vue`);
    },
  },
  watch: {
    // Monitora notificação de status do microfone
    statusMicrofone: function (status) {
      status
        ? this.notifyLive("success", "Status", "Microfone OK")
        : this.notifyLive("error", "Status", "Microfone não ativado");
    },
    // Monitora notificação de status da camera
    statusCamera: function (status) {
      status
        ? this.notifyLive("success", "Status", "Câmera OK")
        : this.notifyLive("error", "Status", "Câmera não ativada");
    },
    // Monitora notificação de conexão
    statusConnection: function (status) {
      this.$notify({
        group: "foo",
        clean: true,
      });
      if (status) {
        this.$notify({
          group: "foo",
          type: "success",
          duration: -1,
          speed: 1000,
          title: "Status",
          text: "Você está conectado na live",
        });
      } else {
        this.$notify({
          group: "foo",
          type: "error",
          duration: -1,
          speed: 1000,
          title: "Erro de conexão",
          text: "Não foi possível conectar no servidor",
        });
      }
    },
  },
  mounted: function () {
    settings
      .fastLogoPrincipal()
      .then((e) => (this.imageSiteLogoHorizontal = e.default));

    if (this.$route.params.id_live) {
      // Valida sessão local, se existir fica verificando validade do token a cada 60s
      if (!this.validaSessao()) {
        this.$router.push(
          "/?login&redirect=live/" + this.$route.params.id_live
        );
      } else {
        this.getFastLive();
      }

      // Informa ao component pai para interromper o loading
      //this.$store.state.fastCarregando = false;
    } else {
      // Carrega script e inicia LIVE API TESTE
      /*this.$loadScript("https://webrtc.github.io/adapter/adapter-latest.js")
        .then(() => {
          this.pageReady();
        })
        .catch(this.errorHandler);*/

      this.carregaJitsiPublica();
    }
  },
  methods: {
    async getFastLive() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_live/lista_usuario_nova?id_live=" +
            this.$route.params.id_live,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          // Busca anexos
          this.getLiveAnexos(obj[0])
            .then((arquivos) => {
              this.fastLiveAnexos = arquivos;
            })
            .catch((e) => {
              console.log(e);
            });
          // Busca dados da plataforma
          this.getPlataformaData(obj[0].id_plataforma);
          obj.forEach((l) => {
            l.iniciou = false;
            l.finalizou = false;
            l.cancelada = false;
            l.permissao = true;
            l.tutor = false;
            if (
              this.retornaSessao(settings.fastSessaoUsuario).Id_Usuario ==
              l.id_usuario_tutor
            ) {
              l.tutor = true;
            }
          });
          if (obj[0].tipo == "Z") {
            this.fastLive = obj[0];
            switch (this.fastLive.status) {
              case "A":
                this.verificaTempo();
                break;
              case "E":
                this.registraPresencaLive();
                this.fastLive.iniciou = true;
                break;
              case "F":
                this.fastLive.finalizou = true;
                break;
              case "C":
                this.fastLive.finalizou = true;
                this.fastLive.cancelada = true;
                break;
              default:
                break;
            }
          } else if (obj[0].tipo == "M") {
            this.fastLive = obj[0];
            switch (this.fastLive.status) {
              case "A":
                this.verificaTempo();
                break;
              case "E":
                this.registraPresencaLive();
                this.fastLive.iniciou = true;
                break;
              case "F":
                this.fastLive.finalizou = true;
                break;
              case "C":
                this.fastLive.finalizou = true;
                this.fastLive.cancelada = true;
                break;
              default:
                break;
            }
          } else if (obj[0].tipo == "A") {
            this.fastLive = obj[0];
            switch (this.fastLive.status) {
              case "A":
                this.verificaTempo();
                break;
              case "E":
                this.registraPresencaLive();
                this.retornaDataFim();
                this.carregaOpenTok();
                this.fastLive.iniciou = true;
                break;
              case "F":
                this.fastLive.finalizou = true;
                break;
              case "C":
                this.fastLive.finalizou = true;
                this.fastLive.cancelada = true;
                break;
              default:
                break;
            }
          } else if (obj[0].tipo == "J") {
            this.fastLive = obj[0];
            switch (this.fastLive.status) {
              case "A":
                this.verificaTempo();
                break;
              case "E":
                this.fastLive.iniciou = true;
                this.registraPresencaLive();
                this.carregaJitsi();

                break;
              case "F":
                this.fastLive.finalizou = true;
                break;
              case "C":
                this.fastLive.finalizou = true;
                this.fastLive.cancelada = true;
                break;
              default:
                break;
            }
          }
        }
        this.$store.state.fastCarregando = false;
        //console.log("getFastLive", json);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async iniciaLive() {
      let model = {
        fast_plataforma_live: this.fastLive,
        id_usuarios_participantes: "",
      };

      this.registraPresencaLive();

      this.$toast.open({
        message: "Iniciando a live...",
        type: "info",
        duration: 5000,
        dismissible: true,
        position: "bottom-right",
      });

      if (this.fastLive.tipo == "Z" || this.fastLive.tipo == "M") {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_live/inicia_live_zoom",
            this.fastAjaxOptions("POST", JSON.stringify(model))
          );
          let json = await resp.json();
          let obj = Array.from(json);
          this.fastLive.iniciou = true;
          this.fastLive.status = "E";
        } catch (e) {
          console.log("Erro", e);
        }
      } else if (this.fastLive.tipo == "A") {
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_plataforma_live/inicia_live",
            this.fastAjaxOptions("POST", JSON.stringify(model))
          );
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj[0].API_TOKEN) {
            this.retornaDataFim();
            this.fastLive.API_TOKEN = obj[0].API_TOKEN;
            this.fastLive.iniciou = true;
            this.fastLive.status = "E";
            this.carregaOpenTok();
          } else {
            this.$toast.open({
              message: "Erro ao iniciar a live",
              type: "error",
              duration: 60000,
              dismissible: true,
              position: "bottom-right",
            });
          }
        } catch (e) {
          console.log("Erro", e);
        }
      } else if (this.fastLive.tipo == "J") {
        this.fastLive.iniciou = true;
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_live/inicia_live_jitsi",
            this.fastAjaxOptions("POST", JSON.stringify(model))
          );
          let json = await resp.json();
          let obj = Array.from(json);
          if (obj.length) {
            this.fastLive.status = "E";
            this.fastLive.API_TOKEN = obj[0].API_TOKEN;
            this.carregaJitsi();
          }
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    async verificaTempo() {
      let dataInicio = new Date(
        this.fastLive.data_live.split("T")[0] + " " + this.fastLive.hora_live
      );
      // Pegar hora no servidor
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_usuario_curso_aula_atividade/lista_hora",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        //let obj = JSON.parse(json);
        let dataAtual = new Date(json.dataAtual);
        console.log("Data atual: ", dataAtual);
        console.log("Data início: ", dataInicio);
        if (dataAtual >= dataInicio) {
          console.log("horário da alcançado...");
          if (
            this.retornaSessao(settings.fastSessaoUsuario).Id_Usuario ==
            this.fastLive.id_usuario_tutor
          ) {
            this.iniciaLive();
          } else {
            this.$toast.open({
              message: "Aguarde o instrutor...",
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            setTimeout(() => {
              this.getFastLive();
            }, 10000);
          }
        } else {
          console.log("Aguarde o início da live...");
          setTimeout(() => {
            this.verificaTempo();
          }, 10000);
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async finalizarLive() {
      console.log("Finalizando live...", this.fastLive);
      if (this.fastLive.id_live && this.fastLive.tutor) {
        this.fastLive.status = "F";
        let model = {
          fast_plataforma_live: this.fastLive,
          id_usuarios_participantes: "",
        };
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_live/finaliza_live_nova",
            this.fastAjaxOptions("POST", JSON.stringify(model))
          );
          let json = await resp.json();
          let obj = Array.from(json);
          if (this.fastLive.tipo == "J") {
            window.location.href = "/Live/" + this.fastLive.id_live;
          } else {
            this.fastLive.finalizou = true;
            this.hideModal("modalFinalizarLive");
          }
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    async getPlataformaData(id_plataforma) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma/lista_detalhes_cliente?id_plataforma=" +
            id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataforma = obj[0];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },

    // Jitsi
    async carregaJitsi() {
      this.$loadScript("https://meet.fastead.com/external_api.js")
        .then(() => {
          const domain = "meet.fastead.com";
          let toolbars = [];
          if (this.fastLive.tutor) {
            toolbars = [
              "localrecording",
              "microphone",
              "camera",
              "closedcaptions",
              "desktop",
              "fullscreen",
              "fodeviceselection",
              "hangup",
              "profile",
              "chat",
              "recording",
              "livestreaming",
              "etherpad",
              "sharedvideo",
              "settings",
              "raisehand",
              "videoquality",
              "filmstrip",
              "feedback",
              "stats",
              "shortcuts",
              "tileview",
              "download",
              "help",
              "mute-everyone",
              "e2ee",
            ];
          } else {
            toolbars = [
              "microphone",
              "camera",
              "closedcaptions",
              "fullscreen",
              "fodeviceselection",
              "profile",
              "chat",
              "settings",
              "raisehand",
              "videoquality",
              "filmstrip",
              "feedback",
              "stats",
              "shortcuts",
              "tileview",
              "download",
              "help",
              "e2ee",
            ];

            this.validaLiveStatus();
          }
          const options = {
            roomName: this.fastLive.id_live,
            width: "100%",
            height: "100%",
            parentNode: document.querySelector("#meet"),
            userInfo: {
              email: this.retornaSessao(settings.fastSessaoUsuario).Email,
              displayName:
                this.retornaSessao(settings.fastSessaoUsuario).First_name +
                " " +
                this.retornaSessao(settings.fastSessaoUsuario).Last_name,
            },
            interfaceConfigOverwrite: {
              TOOLBAR_BUTTONS: toolbars,
              HIDE_INVITE_MORE_HEADER: true,
            },
          };
          const api = new JitsiMeetExternalAPI(domain, options);

          api.addEventListener("readyToClose", (event) => {
            this.registraSaidaLive();
            this.finalizarLive();
          });

          //it will fire only for guests
          api.on("passwordRequired", () => {
            api.executeCommand("password", this.fastLive.API_TOKEN);
          });

          //this will setup the password for 1st user
          api.on("participantRoleChanged", (event) => {
            if (event.role === "moderator") {
              api.executeCommand("password", this.fastLive.API_TOKEN);
            }
          });

          if (this.retornaSessao(settings.fastSessaoUsuario).Image)
            api.executeCommand(
              "avatarUrl",
              this.retornaSessao(settings.fastSessaoUsuario).Image
            );

          // Somente se for tutor = participante saiu (EXISTE UM BUG QUE NÃO É POSSÍVEL CAPTURAR O EMAIL DO USUÁRIO)
          /*
          if (this.fastLive.tutor) {
            api.on("participantLeft", (event) => {
              console.log("participante saiu", event);
              console.log("id do participante é ", event.id);

              setTimeout(function () {
                const email = api.getEmail(event.id);
                console.log(
                  "O avatar do participante é " + api.getAvatarURL(event.id)
                );
                console.log("O email do participante é " + email);
              }, 1000);
            });
          }
          */
        })
        .catch(this.errorHandler);
    },
    async carregaJitsiPublica() {
      this.fastLiveJitsiPublica = true;
      this.fastLive.permissao = true;
      this.$store.state.fastCarregando = false;
      this.$loadScript("https://live.fastead.com/external_api.js")
        .then(() => {
          const domain = "live.fastead.com";
          const options = {
            width: "100%",
            height: "100%",
            parentNode: document.querySelector("#meet"),
          };
          const api = new JitsiMeetExternalAPI(domain, options);
        })
        .catch(this.errorHandler);
    },
    async registraPresencaLive() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_live/registra_presenca",
          this.fastAjaxOptions("POST", JSON.stringify(this.fastLive))
        );
        let json = await resp.json();
      } catch (e) {
        console.log(e);
      }
    },
    async registraSaidaLive() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_live/registra_saida",
          this.fastAjaxOptions("POST", JSON.stringify(this.fastLive))
        );
        let json = await resp.json();
      } catch (e) {
        console.log(e);
      }
    },
    async getLiveAnexos(live) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_live_arquivo/lista?id_live=" +
              live.id_live,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();

          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    // Métodos API opentok
    async carregaOpenTok() {
      // Carrega script
      this.$loadScript("https://static.opentok.com/v2/js/opentok.min.js")
        .then(() => {
          this.buscaApiKey()
            .then((e) => {
              const token = this.fastLive.API_TOKEN.split("&").shift();
              const sessionId = this.fastLive.API_TOKEN.split("&").pop();
              if (this.fastLive.tutor) {
                console.log("Tutor identificado", this.fastLive);
                this.initializeSessionTutor(e, sessionId, token);
              } else {
                console.log("Participante identificado", this.fastLive);

                this.buscaTokenAluno(sessionId).then((t) => {
                  this.initializeSessionUsuario(e, sessionId, t.token);
                  this.validaLiveStatus();
                });
              }
            })
            .catch((e) => {
              console.log("buscaApiKey - Erro", e);
            });
        })
        .catch(this.errorHandler);
    },
    async buscaApiKey() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_plataforma_live/busca_api_key",
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          console.log("Erro", e);
          reject("");
        }
      });
    },
    initializeSessionUsuario(apiKey, sessionId, token) {
      this.openTokSession = OT.initSession(apiKey, sessionId);

      this.exibeUsuariosOnline(
        this.retornaSessao(settings.fastSessaoUsuario).First_name +
          " " +
          this.retornaSessao(settings.fastSessaoUsuario).Last_name,
        "adicionar"
      );
      /*
      console.log(
        "Exibindo usuário online ",
        this.retornaSessao(settings.fastSessaoUsuario).First_name +
          " " +
          this.retornaSessao(settings.fastSessaoUsuario).Last_name
      );*/

      // Subscribe to a newly created stream
      //session.on("streamCreated", function streamCreated(event) {
      this.openTokSession.on("streamCreated", (event) => {
        var subscriberOptionsAluno = {
          insertMode: "append",
          width: "100%",
          height: "100%",
          name: event.stream.connection.data.split("=").pop(),
          style: { nameDisplayMode: "on" },
        };
        console.log(
          "Novo participante",
          event.stream.connection.data.split("=").pop()
        );
        var subscriberOptions = {
          insertMode: "append",
          width: "100%",
          height: "100%",
        };
        var div_participantes = document.querySelector("#subscriber").innerHTML;
        if (typeof div_participantes == "undefined") {
          document
            .querySelector("#divParticipantesLive")
            .append('<div id="subscriber"></div>');
        }
        console.log("event.stream", event.stream);
        // Se for evento de camera
        if (event.stream.videoType == "camera") {
          // Se for aluno
          if (event.stream.connection.data.indexOf("nome_usuario=") != "-1") {
            this.exibeUsuariosOnline(
              event.stream.connection.data.split("=").pop(),
              "adicionar"
            );

            console.log("Aluno detectado ", event.stream.connection.data);

            //session.subscribe(event.stream, "subscriber", subscriberOptionsAluno, function callback(error) {
            this.openTokSession.subscribe(
              event.stream,
              "subscriber",
              subscriberOptionsAluno,
              (error) => {
                if (error) {
                  console.error(
                    "There was an error publishing: ",
                    error.name,
                    error.message
                  );
                }
              }
            );
          } else if (
            event.stream.connection.data.indexOf("nome_usuario_tutor=") != "-1"
          ) {
            // Tutor
            console.log("Tutor detectado ", event.stream.connection.data);
            //session.subscribe(event.stream, "publisher", subscriberOptions, function callback(error) {
            this.openTokSession.subscribe(
              event.stream,
              "publisher",
              subscriberOptions,
              (error) => {
                if (error) {
                  console.error(
                    "There was an error publishing: ",
                    error.name,
                    error.message
                  );
                }
              }
            );
          }

          // Se for compartilhamento de tela
        } else if (event.stream.videoType == "screen") {
          console.log(
            "Compartilhamento detectada ",
            event.stream.connection.data
          );
          this.openTokSession.subscribe(
            event.stream,
            "screen-preview",
            subscriberOptions,
            (error) => {
              if (error) {
                console.error(
                  "There was an error publishing: ",
                  error.name,
                  error.message
                );
              }
            }
          );
        }
      });

      this.openTokSession.on({
        streamDestroyed: (event) => {
          //console.log(event);
          //event.preventDefault();
          var subscribers = this.openTokSession.getSubscribersForStream(
            event.stream
          );
          if (subscribers.length > 0) {
            var subscriber = document.getElementById(subscribers[0].id);
            var nome_usuario = subscribers[0].stream.connection.data
              .split("=")
              .pop();
            // Display error message inside the Subscriber
            subscriber.innerHTML = nome_usuario + " - Offline";
            this.exibeUsuariosOnline(nome_usuario, "remover");
          }
        },
      });

      // Initialize the publisher
      var publisherOptions = {
        insertMode: "append",
        width: "100%",
        height: "100%",
      };
      //var publisher = OT.initPublisher("subscriber", publisherOptions, function initCallback(initErr) {
      var publisher = OT.initPublisher(
        "subscriber",
        publisherOptions,
        (initErr) => {
          if (initErr) {
            console.error(
              "There was an error initializing the publisher: ",
              initErr.name,
              initErr.message
            );
            return;
          }
        }
      );

      // Quando usuário se desconectar, exibe mensagem no chat
      publisher.on("streamDestroyed", (event) => {
        console.log("Stream stopped. Reason: " + event.reason);
        //informaStatusUsuario(event.stream.connection.data.split("=").pop(), "se desconectou da sala");
      });

      // Connect to the session
      //session.connect(token, function callback(error) {
      this.openTokSession.connect(token, (error) => {
        // If the connection is successful, initialize a publisher and publish to the session
        if (!error) {
          //console.log(session);
          // If the connection is successful, publish the publisher to the session
          //session.publish(publisher, function publishCallback(publishErr) {
          this.openTokSession.publish(publisher, (publishErr) => {
            if (publishErr) {
              console.error(
                "There was an error publishing: ",
                publishErr.name,
                publishErr.message
              );
            }
          });
        } else {
          console.error(
            "There was an error connecting to the session: ",
            error.name,
            error.message
          );
        }
      });

      // Receive a message and append it to the history
      var msgHistory = document.querySelector("#history");
      //session.on("signal:msg", function signalCallback(event) {
      this.openTokSession.on("signal:msg", (event) => {
        var msg = document.createElement("p");
        msg.textContent = event.data;
        msg.className =
          event.from.connectionId ===
          this.openTokSession.connection.connectionId
            ? "mine"
            : "theirs";
        msgHistory.appendChild(msg);
        //msg.scrollIntoView();
      });

      this.openTokSession.on({
        signal: (event) => {
          if (event.data === "mutePublisher") {
            // mute publisher
            publisher.publishAudio(false);
          }
        },
      });

      this.openTokSession.on({
        signal: (event) => {
          if (event.data === "materialLiveAtualizado") {
            //htmlBuscaMateriais();
          }
        },
      });

      this.initializeChat();
    },
    initializeSessionTutor(apiKey, sessionId, token) {
      console.log("Iniciando instrutor...");
      this.openTokSession = OT.initSession(apiKey, sessionId);

      // Subscribe to a newly created stream
      this.openTokSession.on("streamCreated", (event) => {
        var subscriberOptions = {
          insertMode: "append",
          width: "100%",
          height: "100%",
          name: event.stream.connection.data.split("=").pop(),
          style: { nameDisplayMode: "on" },
        };

        console.log("streamCreated", event.stream.connection.data);

        this.openTokSession.subscribe(
          event.stream,
          "subscriber",
          subscriberOptions,
          (error) => {
            if (error) {
              console.error(
                "There was an error publishing: ",
                error.name,
                error.message
              );
            }
          }
        );

        this.exibeUsuariosOnline(
          event.stream.connection.data.split("=").pop(),
          "adicionar"
        );

        /* console.log("Exibindo usuário online ", event.stream.connection.data);*/

        if (event.stream.hasVideo) {
          this.informaStatusUsuario(
            event.stream.connection.data.split("=").pop(),
            "está na sala"
          );
        } else {
          this.informaStatusUsuario(
            event.stream.connection.data.split("=").pop(),
            "está na sala, porém sua câmera está desativada"
          );
        }
      });

      this.openTokSession.on({
        streamDestroyed: (event) => {
          var subscribers = this.openTokSession.getSubscribersForStream(
            event.stream
          );
          if (subscribers.length > 0) {
            var subscriber = document.getElementById(subscribers[0].id);
            var nome_usuario = subscribers[0].stream.connection.data
              .split("=")
              .pop();
            // Display error message inside the Subscriber
            subscriber.innerHTML = nome_usuario + " - Offline";
            //event.preventDefault();   // Prevent the Subscriber from being removed
            this.informaStatusUsuario(nome_usuario, "se desconectou da sala");
            this.exibeUsuariosOnline(nome_usuario, "remover");
          }
        },
      });

      // Initialize the publisher
      var publisherOptions = {
        insertMode: "append",
        width: "100%",
        height: "100%",
      };
      //var publisher = OT.initPublisher("publisher", publisherOptions, function initCallback(initErr) {
      var publisher = OT.initPublisher(
        "publisher",
        publisherOptions,
        (initErr) => {
          if (initErr) {
            console.error(
              "There was an error initializing the publisher: ",
              initErr.name,
              initErr.message
            );
            return;
          }
        }
      );

      // Connect to the session
      //session.connect(token, function callback(error) {
      this.openTokSession.connect(token, (error) => {
        // If the connection is successful, initialize a publisher and publish to the session
        if (!error) {
          // If the connection is successful, publish the publisher to the session
          //session.publish(publisher, function publishCallback(publishErr) {
          this.openTokSession.publish(publisher, (publishErr) => {
            if (publishErr) {
              console.error(
                "There was an error publishing: ",
                publishErr.name,
                publishErr.message
              );
            }
          });
        } else {
          console.error(
            "There was an error connecting to the session: ",
            error.name,
            error.message
          );
        }
      });

      // Receive a message and append it to the history
      var msgHistory = document.querySelector("#history");
      //session.on("signal:msg", function signalCallback(event) {
      this.openTokSession.on("signal:msg", (event) => {
        var msg = document.createElement("p");
        msg.textContent = event.data;
        msg.className =
          event.from.connectionId ===
          this.openTokSession.connection.connectionId
            ? "mine"
            : "theirs";
        msgHistory.appendChild(msg);
        msg.scrollIntoView();
      });

      this.openTokSession.on({
        signal: (event) => {
          if (event.data === "mutePublisher") {
            // mute publisher
            publisher.publishAudio(false);
          }
        },
      });

      this.initializeChat();

      //document.querySelector("#btnCompartilharTela").addEventListener("click", () => {
      //this.compartilhaTela();
      //});

      document
        .querySelector("#btnMutarAlunos")
        .addEventListener("click", () => {
          this.mutarAlunos();
        });
    },
    exibeUsuariosOnline(nomeUsuario, acao) {
      var divUsuariosOnline = document.querySelector("#divUsuariosOnline");
      if (!divUsuariosOnline) {
        document.querySelector("#participantes").innerHTML =
          '<div id="divUsuariosOnline" class="mt-4"><h4>Usuários online</h4><ul class="p-2 text-left"></ul></div>';
        if (acao == "adicionar") {
          document.querySelector("#divUsuariosOnline > ul").innerHTML =
            '<li class="text-success">' + nomeUsuario + "</li>";
        }
      } else {
        if (acao === "adicionar") {
          document.querySelector("#divUsuariosOnline > ul").innerHTML +=
            '<li class="text-success">' + nomeUsuario + "</li>";
        } else {
          Array.from(
            document.querySelectorAll("#divUsuariosOnline > ul li")
          ).forEach((e) => {
            if (e.innerHTML === nomeUsuario) {
              e.remove();
            }
          });
        }
      }
    },
    informaStatusUsuario(nome_usuario, status) {
      var d = new Date();
      var hora = d.getHours();
      var minuto = d.getMinutes();
      var segundo = d.getSeconds();

      if (parseInt(hora) < 10) {
        hora = ("0" + hora).slice(-2);
      }
      if (parseInt(minuto) < 10) {
        minuto = ("0" + minuto).slice(-2);
      }
      if (parseInt(segundo) < 10) {
        segundo = ("0" + segundo).slice(-2);
      }
      //console.log(segundo);

      this.openTokSession.signal(
        {
          type: "msg",
          data:
            "[" +
            hora +
            ":" +
            minuto +
            ":" +
            segundo +
            "] - O usuário " +
            nome_usuario +
            " " +
            status,
        },
        //function signalCallback(error) {
        (error) => {
          if (error) {
            console.error("Error sending signal:", error.name, error.message);
          } else {
            msgTxt.value = "";
          }
        }
      );
    },
    initializeChat() {
      console.log("Inicializando chat...");
      // Text chat
      var form = document.querySelector("form");
      var msgTxt = document.querySelector("#msgTxt");

      // Send a signal once the user enters data in the form
      document
        .querySelector("#formChat")
        .addEventListener("submit", (event) => {
          event.preventDefault();
          if (msgTxt.value != "") {
            var d = new Date();
            var hora = d.getHours();
            var minuto = d.getMinutes();
            var segundo = d.getSeconds();

            if (parseInt(hora) < 10) {
              hora = ("0" + hora).slice(-2);
            }
            if (parseInt(minuto) < 10) {
              minuto = ("0" + minuto).slice(-2);
            }
            if (parseInt(segundo) < 10) {
              segundo = ("0" + segundo).slice(-2);
            }

            this.openTokSession.signal(
              {
                type: "msg",
                data:
                  "[" +
                  hora +
                  ":" +
                  minuto +
                  ":" +
                  segundo +
                  "] - " +
                  this.retornaSessao(settings.fastSessaoUsuario).First_name +
                  " " +
                  this.retornaSessao(settings.fastSessaoUsuario).Last_name +
                  " - " +
                  msgTxt.value,
              },
              //function signalCallback(error) {
              (error) => {
                if (error) {
                  console.error(
                    "Error sending signal:",
                    error.name,
                    error.message
                  );
                } else {
                  msgTxt.value = "";
                }
              }
            );
          }
        });
    },
    compartilhaTela() {
      console.log("Compartilhando tela");
      this.statusShareScreen = true;
      OT.checkScreenSharingCapability((response) => {
        if (!response.supported || response.extensionRegistered === false) {
          // This browser does not support screen sharing
        }
      });

      OT.checkScreenSharingCapability((response) => {
        if (!response.supported || response.extensionRegistered === false) {
          // This browser does not support screen sharing.
        } else if (response.extensionInstalled === false) {
          // Prompt to install the extension.
        } else {
          //var htmlCompartilhamento = document.querySelector("#divTelaCompartilhadaLive").innerHTML;
          //document.querySelector("#divTelaCompartilhadaLive").append(document.querySelector("#textchat").html());

          // Screen sharing is available. Publish the screen.
          var publisher = OT.initPublisher(
            "screen-preview",
            { videoSource: "screen" },
            (error) => {
              if (error) {
                //document.querySelector("#btnCompartilharTela").style.display = "block";
                this.statusShareScreen = false;
                let div = document.createElement("div");
                div.id = "screen-preview";
                div.classList.add("screen-preview");
                div.style.height = "500px";
                document
                  .querySelector("#divTelaCompartilhadaLive")
                  .insertBefore(div, document.querySelector("#divChat"));
              } else {
                this.openTokSession.publish(publisher, (error) => {
                  if (error) {
                    this.statusShareScreen = false;
                    //document.querySelector("#btnCompartilharTela").style.display = "block";
                    let div = document.createElement("div");
                    div.id = "screen-preview";
                    div.classList.add("screen-preview");
                    div.style.height = "500px";
                    document
                      .querySelector("#divTelaCompartilhadaLive")
                      .insertBefore(div, document.querySelector("#divChat"));
                  }
                });
              }
            }
          );
          document.querySelector("#screen-preview").style.width = "100%";
          document.querySelector("#screen-preview").style.height = "500px";
          //document.querySelector("#btnCompartilharTela").style.display = "none";

          publisher.on("mediaStopped", (event) => {
            console.log("Interrompendo compartilhamento...");
            this.statusShareScreen = false;
            //document.querySelector("#btnCompartilharTela").style.display = "block";
            let div = document.createElement("div");
            div.id = "screen-preview";
            div.classList.add("screen-preview");
            div.style.height = "500px";
            document
              .querySelector("#divTelaCompartilhadaLive")
              .insertBefore(div, document.querySelector("#divChat"));
          });
        }
      });
    },
    mutarAlunos() {
      this.openTokSession.signal(
        {
          data: "mutePublisher",
        },
        (error) => {
          if (error) {
            console.log("signal error (" + error.name + "): " + error.message);
          } else {
            console.log("signal sent.");
          }
        }
      );
    },
    retornaDataFim() {
      let dataInicio = new Date(
        this.fastLive.data_live.split("T")[0] + " " + this.fastLive.hora_live
      );
      let minutos =
        parseInt(this.fastLive.duracao_live.split(":")[0]) * 60 +
        parseInt(this.fastLive.duracao_live.split(":")[1]);
      var dt = new Date(dataInicio.getTime() + minutos * 60000);

      this.dataTermino =
        dt.getFullYear().toString().padStart(4, "0") +
        "-" +
        (dt.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        dt.getDate().toString().padStart(2, "0") +
        " " +
        dt.getHours().toString().padStart(2, "0") +
        ":" +
        dt.getMinutes().toString().padStart(2, "0") +
        ":" +
        dt.getSeconds().toString().padStart(2, "0");
    },
    async validaLiveStatus() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_live/lista_usuario?id_live=" +
            this.fastLive.id_live,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        console.log(json);
        let objLive = Array.from(json);
        if (objLive.length > 0) {
          let obj = objLive[0];
          if (obj.status == "F" || obj.status == "C") {
            location.reload();
          } else {
            if (obj.status == "E") console.log("Live em execução");
            setTimeout(() => {
              this.validaLiveStatus();
            }, 30000);
          }
        } else {
          location.reload();
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async buscaTokenAluno(sessionId) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_live/gera_token?sessionId=" +
              sessionId,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          resolve(JSON.parse(json));
        } catch (e) {
          reject(e);
        }
      });
    },

    // LAYOUTS DESKTOP
    //Layout original
    fastLayoutOriginal() {
      document.querySelector("#screen-preview").className = "";
      document.querySelector("#divVideoInstrutorLive").className = "";
      document.querySelector("#divTelaCompartilhadaLive").className = "";
      document.querySelector("#divParticipantesLive").className = "";
      document
        .querySelector("#divVideoInstrutorLive")
        .classList.add("col-sm-12", "col-md-3", "col-lg-2");
      document
        .querySelector("#divTelaCompartilhadaLive")
        .classList.add("col-sm-12", "col-md-6", "col-lg-8");
      document
        .querySelector("#divParticipantesLive")
        .classList.add("col-sm-12", "col-md-3", "col-lg-2");

      document.querySelector("#btnLayoutOriginal").className = "".classList.add(
        "btn",
        "btn-danger"
      );
      document.querySelector("#btnLayoutTutor").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutShare").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutChat").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );

      document.querySelector("#textchat").classList.add("mt-4");
    },

    //Layout com enfase no tutor
    fastLayoutTutor() {
      document.querySelector("#screen-preview").className = "";
      document.querySelector("#divVideoInstrutorLive").className = "";
      document.querySelector("#divTelaCompartilhadaLive").className = "";
      document.querySelector("#divParticipantesLive").className = "";
      document
        .querySelector("#divVideoInstrutorLive")
        .classList.add("col-sm-12", "col-md-4", "col-lg-3");
      document
        .querySelector("#divTelaCompartilhadaLive")
        .classList.add("col-sm-12", "col-md-6", "col-lg-7");
      document
        .querySelector("#divParticipantesLive")
        .classList.add("col-sm-12", "col-md-2", "col-lg-2");

      document.querySelector("#btnLayoutOriginal").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutTutor").className = "".classList.add(
        "btn",
        "btn-danger"
      );
      document.querySelector("#btnLayoutShare").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutChat").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );

      document.querySelector("#textchat").classList.add("mt-4");
    },

    //Layout com enfase no compartilhamento
    fastLayoutShare() {
      document.querySelector("#screen-preview").className = "";
      document.querySelector("#screen-preview").style.maxWidth = "";
      document.querySelector("#screen-preview").style.width = "100%";
      document.querySelector("#screen-preview").style.heigh = "auto";
      document.querySelector("#divVideoInstrutorLive").className = "";
      document.querySelector("#divTelaCompartilhadaLive").className = "";
      document.querySelector("#divParticipantesLive").className = "";
      document.querySelector("#divVideoInstrutorLive").classList.add("d-none");
      document
        .querySelector("#divTelaCompartilhadaLive")
        .classList.add("col-sm-12", "col-md-6", "col-lg-12");
      document.querySelector("#divParticipantesLive").classList.add("d-none");

      document.querySelector("#btnLayoutOriginal").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutTutor").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutShare").className = "".classList.add(
        "btn",
        "btn-danger"
      );
      document.querySelector("#btnLayoutChat").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );

      document.querySelector("#textchat").classList.add("mt-4");
    },

    // Layout com enfase no chat
    fastLayoutChat() {
      document.querySelector("#divVideoInstrutorLive").className = "";
      document.querySelector("#divTelaCompartilhadaLive").className = "";
      document.querySelector("#divParticipantesLive").className = "";
      document
        .querySelector("#divVideoInstrutorLive")
        .classList.add("col-sm-12", "col-md-3", "col-lg-2");
      document
        .querySelector("#divTelaCompartilhadaLive")
        .classList.add("col-sm-12", "col-md-6", "col-lg-8");
      document
        .querySelector("#divParticipantesLive")
        .classList.add("col-sm-12", "col-md-3", "col-lg-2");
      document.querySelector("#screen-preview").classList.add("d-none");

      document.querySelector("#btnLayoutOriginal").className = "".classList.add(
        "btn btn-outline-danger"
      );
      document.querySelector("#btnLayoutTutor").className = "".classList.add(
        "btn btn-outline-danger"
      );
      document.querySelector("#btnLayoutShare").className = "".classList.add(
        "btn btn-outline-danger"
      );
      document.querySelector("#btnLayoutChat").className = "".classList.add(
        "btn btn-danger"
      );

      document.querySelector("#textchat").className = "";
    },

    // LAYOUTS MOBILE
    //Layout original
    fastLayoutOriginalMob() {
      document.querySelector("#screen-preview").className = "";
      document.querySelector("#divVideoInstrutorLive").className = "";
      document.querySelector("#divParticipantesLive").className = "";
      document.querySelector("#divChat").className = "";

      document
        .querySelector("#divVideoInstrutorLive")
        .classList.add("col-sm-12", "col-md-3", "col-lg-2");
      document
        .querySelector("#divParticipantesLive")
        .classList.add("col-sm-12", "col-md-3", "col-lg-2");

      document.querySelector(
        "#btnLayoutOriginalMob"
      ).className = "".classList.add("btn", "btn-danger");
      document.querySelector("#btnLayoutTutorMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutShareMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutChatMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector(
        "#btnLayoutParticipantesMob"
      ).className = "".classList.add("btn", "btn-outline-danger");

      document
        .querySelector("#textchat")
        .classList.add("mt-4")
        .classList.add("mb-4");
    },

    fastLayoutTutorMob() {
      document.querySelector("#screen-preview").className = "";
      document.querySelector("#divVideoInstrutorLive").className = "";
      document.querySelector("#divParticipantesLive").className = "";
      document.querySelector("#divChat").className = "";

      document
        .querySelector("#divVideoInstrutorLive")
        .classList.add("col-sm-12", "col-md-3", "col-lg-2");
      document.querySelector("#screen-preview").classList.add("d-none");
      document.querySelector("#divParticipantesLive").classList.add("d-none");
      document.querySelector("#divChat").classList.add("d-none");

      document.querySelector(
        "#btnLayoutOriginalMob"
      ).className = "".classList.add("btn", "btn-outline-danger");
      document.querySelector("#btnLayoutTutorMob").className = "".classList.add(
        "btn",
        "btn-danger"
      );
      document.querySelector("#btnLayoutShareMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutChatMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector(
        "#btnLayoutParticipantesMob"
      ).className = "".classList.add("btn", "btn-outline-danger");

      document.querySelector("#textchat").className = "";
    },

    fastLayoutShareMob() {
      document.querySelector("#screen-preview").className = "";
      document.querySelector("#divVideoInstrutorLive").className = "";
      document.querySelector("#divParticipantesLive").className = "";
      document.querySelector("#divChat").className = "";

      document.querySelector("#divVideoInstrutorLive").classList.add("d-none");
      document
        .querySelector("#screen-preview")
        .classList.add("col-sm-12", "col-md-12", "col-lg-12");
      document.querySelector("#divParticipantesLive").classList.add("d-none");
      document.querySelector("#divChat").classList.add("d-none");

      document.querySelector(
        "#btnLayoutOriginalMob"
      ).className = "".classList.add("btn", "btn-outline-danger");
      document.querySelector("#btnLayoutTutorMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutShareMob").className = "".classList.add(
        "btn",
        "btn-danger"
      );
      document.querySelector("#btnLayoutChatMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector(
        "#btnLayoutParticipantesMob"
      ).className = "".classList.add("btn", "btn-outline-danger");

      document.querySelector("#textchat").className = "";
    },

    fastLayoutChatMob() {
      document.querySelector("#screen-preview").className = "";
      document.querySelector("#divVideoInstrutorLive").className = "";
      document.querySelector("#divParticipantesLive").className = "";
      document.querySelector("#divChat").className = "";

      document.querySelector("#divVideoInstrutorLive").classList.add("d-none");
      document.querySelector("#screen-preview").classList.add("d-none");
      document.querySelector("#divParticipantesLive").classList.add("d-none");

      document.querySelector(
        "#btnLayoutOriginalMob"
      ).className = "".classList.add("btn", "btn-outline-danger");
      document.querySelector("#btnLayoutTutorMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutShareMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutChatMob").className = "".classList.add(
        "btn",
        "btn-danger"
      );
      document.querySelector(
        "#btnLayoutParticipantesMob"
      ).className = "".classList.add("btn", "btn-outline-danger");

      document.querySelector("#textchat").className = "";
    },

    fastLayoutParticipantesMob() {
      document.querySelector("#screen-preview").className = "";
      document.querySelector("#divVideoInstrutorLive").className = "";
      document.querySelector("#divParticipantesLive").className = "";
      document.querySelector("#divChat").className = "";

      document.querySelector("#divVideoInstrutorLive").classList.add("d-none");
      document.querySelector("#screen-preview").classList.add("d-none");
      document.querySelector("#divChat").classList.add("d-none");
      document
        .querySelector("#divParticipantesLive")
        .classList.add("col-sm-12", "col-md-12", "col-lg-12");

      document.querySelector(
        "#btnLayoutOriginalMob"
      ).className = "".classList.add("btn", "btn-outline-danger");
      document.querySelector("#btnLayoutTutorMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutShareMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector("#btnLayoutChatMob").className = "".classList.add(
        "btn",
        "btn-outline-danger"
      );
      document.querySelector(
        "#btnLayoutParticipantesMob"
      ).className = "".classList.add("btn", "btn-danger");

      document.querySelector("#textchat").className = "";
    },

    // MÉTODOS DA API TESTE
    pageReady() {
      this.uuid = this.createUUID();
      this.localVideo = document.getElementById("localVideo");
      this.remoteVideo = document.getElementById("remoteVideo");

      this.$notify({
        group: "foo",
        type: "info",
        duration: -1,
        speed: 1000,
        title: "Status",
        text: "Conectando no servidor...",
      });

      // Verifica conexão com o server
      this.connect();
    },
    connect() {
      return new Promise(() => {
        // Faz conexão via websocket
        this.serverConnection = new WebSocket(this.webSocketUrl);
        this.serverConnection.onopen = () => {
          // Gera notificação de conexão ok
          this.statusConnection = true;

          // Informções útil - user-agent
          this.userAgent = navigator.appVersion;
          this.language = navigator.language;
          this.platform = navigator.platform;

          // Verifica dispositivo de audio e video
          this.checkUserDevices();
        };
        this.serverConnection.onerror = () => {
          // Gera notificação de erro na conexão
          this.statusConnection = false;
        };
      });
    },
    // Verifica dispositivo de audio e video
    checkUserDevices() {
      this.serverConnection.onmessage = this.gotMessageFromServer;
      // Verifica audio e video
      if (navigator.mediaDevices.getUserMedia) {
        let checking = ["audioinput", "videoinput"];
        let onlyHas = [];
        let notifyLive = this.notifyLive;
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              onlyHas.push(device.kind);
              if (device.kind == checking[1]) {
                this.statusCamera = true;
              }
              if (device.kind == checking[0]) {
                this.statusMicrofone = true;
              }
            });
            if (!this.statusMicrofone) this.statusMicrofone = false;
            if (!this.statusCamera) this.statusCamera = false;

            let constraints = {};
            if (this.statusMicrofone && !this.statusCamera) {
              constraints = {
                audio: true,
                video: false,
              };
            } else if (!this.statusMicrofone && this.statusCamera) {
              constraints = {
                audio: false,
                video: true,
              };
            } else if (this.statusMicrofone && this.statusCamera) {
              constraints = {
                audio: true,
                video: true,
              };
            }
            // Se não tive nem microfone e nem camera não segue
            if (!this.statusMicrofone && !this.statusCamera) {
              this.$notify({
                group: "foo",
                type: "error",
                duration: -1,
                speed: 1000,
                title: "Nenhum dispositivo detectado",
                text:
                  "Seu microfone e sua câmera não foram detectados. Verifique a permissão de acesso aos dispositivos no seu navegador e atualize a página.",
              });
            } else {
              navigator.mediaDevices
                .getUserMedia(constraints)
                .then(this.getUserMediaSuccess)
                .catch(this.errorHandler);
            }
          })
          .catch((err) => {
            notifyLive("Error", err.name, err.message);
          });
      } else {
        this.notifyLive(
          "error",
          "Erro de compatibilidade",
          "Seu navegador não suporta a API"
        );
      }
    },

    notifyLive(type, title, msg) {
      this.$notify({
        group: "foo",
        type: type,
        duration: 5000,
        speed: 1000,
        title: title,
        text: msg,
      });
    },

    getUserMediaSuccess(stream) {
      //console.info("getUserMediaSuccess", stream);
      this.localStream = stream;
      this.localVideo.srcObject = stream;

      // Entra na sessão da live
      this.start(true);
    },

    start(isCaller) {
      this.peerConnection = new RTCPeerConnection(this.peerConnectionConfig);
      this.peerConnection.onicecandidate = this.gotIceCandidate;
      this.peerConnection.ontrack = this.gotRemoteStream;
      this.peerConnection.addStream(this.localStream);

      if (isCaller) {
        const offerOptions = {
          // New spec states offerToReceiveAudio/Video are of type long (due to
          // having to tell how many "m" lines to generate).
          // http://w3c.github.io/webrtc-pc/#idl-def-RTCOfferAnswerOptions.
          offerToReceiveAudio: this.statusMicrofone ? 1 : 0,
          offerToReceiveVideo: this.statusMicrofone ? 1 : 0,
          iceRestart: true,
          voiceActivityDetection: true,
        };

        this.peerConnection
          .createOffer(offerOptions)
          .then(this.createdDescription)
          .catch(this.errorHandler);
      }
    },

    gotMessageFromServer(message) {
      if (!this.peerConnection) this.start(false);

      if (!this.peerConnection) console.log(message);

      var signal = JSON.parse(message.data);
      console.log(signal);
      let limit = false;

      // Captura participantes e insere no array
      let uuid = JSON.parse(message.data).uuid;
      if (this.participantes.length == 0) {
        this.participantes.push({ uuid: uuid });
      } else {
        let arrayUuid = this.participantes.map((p) => {
          return p.uuid;
        });
        if (arrayUuid.indexOf(uuid) === -1)
          this.participantes.push({ uuid: uuid });

        if (this.participantes.length > 2) limit = true;
      }

      // Ignore messages from ourself
      if (signal.uuid == this.uuid || limit) return;

      if (signal.sdp) {
        this.peerConnection
          .setRemoteDescription(new RTCSessionDescription(signal.sdp))
          .then(() => {
            // Only create answers in response to offers
            if (signal.sdp.type == "offer") {
              const offerOptions = {
                // New spec states offerToReceiveAudio/Video are of type long (due to
                // having to tell how many "m" lines to generate).
                // http://w3c.github.io/webrtc-pc/#idl-def-RTCOfferAnswerOptions.
                offerToReceiveAudio: this.statusMicrofone ? 1 : 0,
                offerToReceiveVideo: this.statusMicrofone ? 1 : 0,
                iceRestart: true,
                voiceActivityDetection: true,
              };
              this.peerConnection
                .createAnswer(offerOptions)
                .then(this.createdDescription)
                .catch(this.errorHandler);
            }
          })
          .catch(this.errorHandler);
      } else if (signal.ice) {
        this.peerConnection
          .addIceCandidate(new RTCIceCandidate(signal.ice))
          .catch(this.errorHandler);
      }
    },

    async startShareScreen() {
      //this.stream = await this.onShareScreen();
      const videoElem = document.getElementById("video");
      var displayMediaOptions = {
        video: {
          cursor: "always",
        },
        audio: false,
      };
      videoElem.srcObject = await navigator.mediaDevices.getDisplayMedia(
        displayMediaOptions
      );

      // this.mediaRecorder = new MediaRecorder(this.stream, { mimeType: "video/webm" });
      // this.mediaRecorder.addEventListener("dataavailable", (event) => {
      //   if (event.data && event.data.size > 0) {
      //     this.chunks.push(event.data);
      //   }
      // });
      // this.mediaRecorder.start(10);
    },

    onShareScreen() {
      var displayMediaOptions = {
        video: {
          cursor: "always",
        },
        audio: false,
      };
      return navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
      // if (navigator.getDisplayMedia) {
      //   return navigator.getDisplayMedia({ video: true });
      // } else if (navigator.mediaDevices.getDisplayMedia) {
      //   return navigator.mediaDevices.getDisplayMedia({ video: true });
      // } else {
      //   return navigator.mediaDevices.getUserMedia({ video: { mediaSource: "screen" } });
      // }
    },

    stopShareScreen() {
      const videoElem = document.getElementById("video");
      let tracks = videoElem.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoElem.srcObject = null;
    },
    gotIceCandidate(event) {
      //console.log(event);
      if (event.candidate != null) {
        this.serverConnection.send(
          JSON.stringify({ ice: event.candidate, uuid: this.uuid })
        );
      }
    },

    createdDescription(description) {
      //console.log("got description");
      this.peerConnection
        .setLocalDescription(description)
        .then(() => {
          this.serverConnection.send(
            JSON.stringify({
              sdp: this.peerConnection.localDescription,
              uuid: this.uuid,
            })
          );
        })
        .catch(this.errorHandler);
    },

    gotRemoteStream(event) {
      //console.log("got remote stream", event.streams);
      this.remoteVideo.srcObject = event.streams[0];
    },

    errorHandler(error) {
      console.log(error);
      this.$notify({
        group: "foo",
        type: "error",
        duration: 5000,
        speed: 1000,
        title: "Erro identificado",
        text: error,
      });
    },

    // Taken from http://stackoverflow.com/a/105074/515584
    // Strictly speaking, it's not a real UUID, but it gets the job done here
    createUUID() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }

      return (
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
      );
    },

    // teste
    teste() {
      alert("Teste");
    },
  },
};
</script>

<style scoped>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
body {
  font-size: 16px;
  background-color: #f5f5f5;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#divTelaCompartilhadaLive {
  z-index: 1;
}

#divCabLive {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  padding: 10px;
  background-color: #fff;
}

#divVideoInstrutorLive {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

#screen-preview {
  height: auto;
  width: 100%;
  max-width: 100%;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  background-color: #000;
  /*background-image: url('https://concurseiro.com/assets/images/live.gif');*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  background-size: 100%;
  resize: both;
  overflow: auto;
  background-clip: content-box;
  padding: 10px;
}

#subscriber {
  height: 200px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  background-color: #000;
}

#subscriber > div {
  margin-bottom: 20px;
}

#divDetalhesLive {
  text-align: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
}

#publisher {
  height: auto;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  background-color: #000;
}

#textchat {
  height: 200px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  overflow: auto;
  padding: 20px;
  background-color: #fff;
  /*resize: both;*/
  overflow: auto;
  max-width: 100%;
}

#divParticipantesLive {
  background-color: #fff;
}

.time_circles {
  position: relative;
  width: 100%;
  height: 100%;
}

.time_circles > div {
  position: absolute;
  text-align: center;
}

.time_circles > div > h4 {
  margin: 0px;
  padding: 0px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Century Gothic", Arial;
}
.time_circles > div > span {
  display: block;
  width: 100%;
  text-align: center;
  font-family: "Century Gothic", Arial;
  font-size: 300%;
  margin-top: 0.4em;
  font-weight: bold;
}

#fasteadLoadingLive {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

@media all and (max-width: 600px) {
  #divInfoDuracao {
    display: none;
  }
  #divInfoTitulo {
    display: none;
  }
  #divInfoDetalhes {
    display: none;
  }
  #divInfoPlataforma {
    display: none;
  }
  #divDetalhesLive {
    padding: 0;
  }
  #publisher {
    height: auto;
    padding: 20px;
  }
  #DateCountdown .time_circles {
    margin: 20px;
  }
  #divTempoLive {
    display: none;
  }
  #divUsuariosOnline {
    display: none;
  }
  #screen-preview {
    height: auto;
  }
  #divParticipantesLive {
    min-height: 300px;
  }
}
</style>
